<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="未回复" name="first">
        <div class="Lists" v-for="(item,index) in List1" :key="index" @click="Go_Details(item.complaint_id)">
          <div class="Lists_Left">
            <img :src="Split(item.evidential_materials)[0]" alt="">
          </div>
          <div class="Lists_Right">
            <div class="Lists_Right_top">
              <div class="Lists_Right_top_L">
                <img :src="item.headimgurl" alt="">
                <div class="name">{{item.nickname}}</div>
                <div class="data">{{NewData(item.create_time).slice(0,9)}}</div>
              </div>
              <div class="Lists_Right_top_R" v-if="item.status ==2">
                <span>已完成</span>
              </div>
              <div class="Lists_Right_top_R1" v-if="item.status ==5">
                <span>未通过</span>
              </div>
              <div class="Lists_Right_top_R2" v-if="item.status ==1">
                <span>处理中</span>
              </div>
              <div class="Lists_Right_top_R3" v-if="item.status ==0">
                <span>审核中</span>
              </div>
              <div class="Lists_Right_top_R4" v-if="item.status ==4">
                <span>已撤销</span>
              </div>
            </div>
            <div class="Lists_Right_title">
              {{item.statement_title}}
            </div>
            <div class="Lists_Right_content">
              <div class="Lists_Right_content_T">
                <span>[投诉对象]</span><span>{{item.name}}</span>
              </div>
              <div class="Lists_Right_content_B">
                <span>[投诉理由]</span><span>{{item.complaint_reason}}</span>
              </div>
            </div>
            <div class="Lists_Right_bottom">
              <div class="Lists_Right_bottom_L">
                <div>
                  <img src="@/assets/img/dianzan_icon.png" alt="">
                  <span>{{item.like_num}}</span>
                </div>
                <div>
                  <img src="@/assets/img/pinglun_icon.png" alt="">
                  <span>{{item.comment_num}}</span>
                </div>
              </div>
              <div class="Lists_Right_bottom_R">
                <div class="Immediate_evaluation1"  v-if="item.status == 0">去管理</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已回复" name="second">
        <div class="Lists" v-for="(item,index) in List2" :key="index" @click="Go_Details(item.complaint_id)">
          <div class="Lists_Left">
            <img :src="Split(item.evidential_materials)[0]" alt="">
          </div>
          <div class="Lists_Right">
            <div class="Lists_Right_top">
              <div class="Lists_Right_top_L">
                <img :src="item.headimgurl" alt="">
                <div class="name">{{item.nickname}}</div>
                <div class="data">{{NewData(item.create_time).slice(0,9)}}</div>
              </div>
              <div class="Lists_Right_top_R" v-if="item.status ==2">
                <span>已完成</span>
              </div>
              <div class="Lists_Right_top_R1" v-if="item.status ==5">
                <span>未通过</span>
              </div>
              <div class="Lists_Right_top_R2" v-if="item.status ==1">
                <span>处理中</span>
              </div>
              <div class="Lists_Right_top_R3" v-if="item.status ==0">
                <span>审核中</span>
              </div>
              <div class="Lists_Right_top_R4" v-if="item.status ==4">
                <span>已撤销</span>
              </div>
            </div>
            <div class="Lists_Right_title">
              {{item.statement_title}}
            </div>
            <div class="Lists_Right_content">
              <div class="Lists_Right_content_T">
                <span>[投诉对象]</span><span>{{item.name}}</span>
              </div>
              <div class="Lists_Right_content_B">
                <span>[投诉理由]</span><span>{{item.complaint_reason}}</span>
              </div>
            </div>
            <div class="Lists_Right_bottom">
              <div class="Lists_Right_bottom_L">
                <div>
                  <img src="@/assets/img/dianzan_icon.png" alt="">
                  <span>{{item.like_num}}</span>
                </div>
                <div>
                  <img src="@/assets/img/pinglun_icon.png" alt="">
                  <span>{{item.comment_num}}</span>
                </div>
              </div>
              <div class="Lists_Right_bottom_R">
                <div class="Immediate_evaluation1"  v-if="item.status == 0">去管理</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="third">
        <div class="Lists" v-for="(item,index) in List3" :key="index" @click="Go_Details(item.complaint_id)">
          <div class="Lists_Left">
            <img :src="Split(item.evidential_materials)[0]" alt="">
          </div>
          <div class="Lists_Right">
            <div class="Lists_Right_top">
              <div class="Lists_Right_top_L">
                <img :src="item.headimgurl" alt="">
                <div class="name">{{item.nickname}}</div>
                <div class="data">{{NewData(item.create_time).slice(0,9)}}</div>
              </div>
              <div class="Lists_Right_top_R" v-if="item.status ==2">
                <span>已完成</span>
              </div>
              <div class="Lists_Right_top_R1" v-if="item.status ==5">
                <span>未通过</span>
              </div>
              <div class="Lists_Right_top_R2" v-if="item.status ==1">
                <span>处理中</span>
              </div>
              <div class="Lists_Right_top_R3" v-if="item.status ==0">
                <span>审核中</span>
              </div>
              <div class="Lists_Right_top_R4" v-if="item.status ==4">
                <span>已撤销</span>
              </div>
            </div>
            <div class="Lists_Right_title">
              {{item.statement_title}}
            </div>
            <div class="Lists_Right_content">
              <div class="Lists_Right_content_T">
                <span>[投诉对象]</span><span>{{item.name}}</span>
              </div>
              <div class="Lists_Right_content_B">
                <span>[投诉理由]</span><span>{{item.complaint_reason}}</span>
              </div>
            </div>
            <div class="Lists_Right_bottom">
              <div class="Lists_Right_bottom_L">
                <div>
                  <img src="@/assets/img/dianzan_icon.png" alt="">
                  <span>{{item.like_num}}</span>
                </div>
                <div>
                  <img src="@/assets/img/pinglun_icon.png" alt="">
                  <span>{{item.comment_num}}</span>
                </div>
              </div>
              <div class="Lists_Right_bottom_R">
                <div class="Immediate_evaluation1"  v-if="item.status == 0">去管理</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

    </el-tabs>
  </div>
</template> 

<script>
import { ComplaintUser_info,attestation_user_list } from "../../api/api.js";
export default {
  data() {
    return {
      activeName: "first",
      List1: [], //未回复
      List2:[],   //已回复
      List3:[],   //已完成
    };
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    ComplaintUser_info() {
      var that = this;
      ComplaintUser_info({ type: 0 }).then((res) => {
        //console.log(res.data.data.data);
        that.List1 = res.data.data.data; //全部
      });
    },
    NewData(timestamp4){
     return  new Date(parseInt(timestamp4) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');
    },
    Split(s){
      return s.split(",");
    },
    // 未回复
    attestation_user_list(){
      var that=this;
      attestation_user_list({ response_status: 0 }).then((res) => {
        //console.log(res.data.data.data);
        that.List1 = res.data.data.data; 
      });
    },
    // 已回复
    attestation_user_list1(){
      var that=this;
      attestation_user_list({ response_status: 1 }).then((res) => {
        //console.log(res.data.data.data);
        that.List2 = res.data.data.data; 
      });
    },
    // 已完成
    attestation_user_list2(){
      var that=this;
      attestation_user_list({ type: 1 }).then((res) => {
        //console.log(res.data.data);
        that.List3 = res.data.data.data; 
      });
    },
    Go_Details(id){
     //console.log(id);
      this.$router.push({
        path: "/EnterpriseCenter/CDetails",
        query: { id: id },
      });
    }
  },
  mounted() {
    var that = this;
    // that.ComplaintUser_info();
    that.attestation_user_list();
    that.attestation_user_list1();
    that.attestation_user_list2();
  },
};
</script>

<style scoped>
>>> .el-tabs {
  width: 960px;
  background: #fff;
  margin-left: 20px;
  padding: 30px 0px 40px 0px;
}
>>> .el-tabs__nav-wrap {
  padding: 0 20px;
}
.list {
  padding: 0 20px 20px;
}
.Lists {
  width: 96%;
  /* width: calc(100% - 20px); */
  border-bottom: 1px solid #e8e8e8;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  padding: 0 0 20px 0;
  cursor: pointer;
}
.Lists:nth-child(1) {
  margin-top: 0;
}
.Lists_Left img {
  width: 208px;
  height: 204px;
  margin-right: 10px;
}
.Lists_Right_top {
  display: flex;
  width: 670px;
  justify-content: space-between;
}
.Lists_Right_top_L img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
}
.Lists_Right_top_L {
  display: flex;
  align-items: center;
}
.Lists_Right_top_L .name {
  margin-right: 25px;
  font-size: 18px;
  color: #333333;
}
.Lists_Right_top_L .data {
  color: #999999;
  font-size: 16px;
}
.Lists_Right_top_R {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #d2e3f7;
  border-radius: 4px;
  text-align: center;
}
.Lists_Right_top_R1 {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #cfcfcf;
  border-radius: 4px;
  text-align: center;
}
.Lists_Right_top_R2 {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #ffe9d3;
  border-radius: 4px;
  text-align: center;
  color: #fe9025;
}
.Lists_Right_top_R3 {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #d9f2ea;
  border-radius: 4px;
  text-align: center;
  color: #51c19a;
}
.Lists_Right_top_R4 {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #cfcfcf;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}
.Lists_Right_top_R span {
  color: #1f72d9;
}
.Lists_Right_title {
  width: 670px;
  font-size: 22px;
  color: #333333;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Lists_Right_content_T {
  margin-top: 15px;
  margin-bottom: 10px;
}
.Lists_Right_content_T span:nth-child(1) {
  color: #1f72d9;
  margin-right: 5px;
}
.Lists_Right_content_T span:nth-child(2) {
  color: #666666;
}
.Lists_Right_content_B {
  width: 470px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Lists_Right_content_B span:nth-child(1) {
  color: #1f72d9;
  margin-right: 5px;
}
.Lists_Right_content_B span:nth-child(2) {
  color: #666666;
}
.Lists_Right_bottom {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.Lists_Right_bottom_L {
  display: flex;
}
.Lists_Right_bottom_L div {
  display: flex;
  align-items: center;
  margin-right: 25px;
  color: #999999;
}
.Lists_Right_bottom_L img {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.Immediate_evaluation {
  width: 120px;
  height: 36px;
  border: 1px solid #1f72d9;
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  color: #1f72d9;
  cursor: pointer;
  margin-left: 20px;
}
.Immediate_evaluation1 {
  width: 100px;
  height: 36px;
  background: #1f72d9;
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
}
.Lists_Right_bottom_R {
  display: flex;
}
</style>